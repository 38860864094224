<template>
  <div>
    <section class="section is-main-section mb-0 pb-0">
      <div class="box is-flex is-align-items-center">
        <div class="is-flex is-justify-content-flex-start is-flex-grow-1">
          <div class="title is-5">{{`${form.name} ${form.surname} - ${form.userId} ${form.isProblem ? '- Problemli Yatırımcı' : ''}`}}</div>
        </div>
        <div class="is-flex is-justify-content-end is-flex-grow-1">
          <div class="control mr-2">
            <b-button v-if="blacklistId" type="is-success" size="is-small" @click="removeBlacklist">Blacklist'ten Çıkart</b-button>
            <b-button v-if="!blacklistId" type="is-dark" size="is-small" @click="addBlacklist">Blacklist'e Ekle</b-button>
          </div>
          <div class="control mr-2">
            <b-button v-if="form.isProblem" type="is-success" size="is-small" @click="removeProblem">Problemsiz olarak değiştir</b-button>
            <b-button v-if="!form.isProblem" type="is-danger" size="is-small" @click="addProblem">Problemli olarak değiştir</b-button>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section pb-0">
      <div class="tile is-ancestor is-flex-wrap-wrap">
        <div class="tile is-parent is-3" v-for="(item, key) in totals" :key="key">
          <article class="tile is-child">
            <card-component :title="`${enums.transactionTypes[item.status]} (${item.count} adet)`"
                            :title-class="`${item.status === 'SUCCESSFUL' ? 'has-background-success has-text-white' :
                            (item.status === 'PENDING' ? 'has-background-warning' : 'has-text-danger')}`">
              <div class="is-widget-label is-align-self-flex-start">
                <h1 class="title is-spaced mb-9">
                  <growing-number :value="parseFloat(item.total)" :prefix="'₺'"/>
                </h1>
              </div>
              <div class="is-widget-label is-align-self-flex-start mt-2">
                <div class="subtitle is-7 mb-0">
                  Ort. Yatırım: ₺{{numberFormat(item.avg)}}
                </div>
              </div>

            </card-component>
          </article>
        </div>
      </div>

    </section>
    <section class="section is-main-section mt-0">
      <finance-table ref="table" :settings="table.settings" :filter="table.filter" :page="table.page"/>
    </section>
  </div>
</template>

<script>
import {BlacklistService, CustomerService as Service, TransactionService} from '@/services'
import DepositStatus from "@/components/DepositStatus.vue";
import CardComponent from "@/components/CardComponent.vue";
import GrowingNumber from "@/components/GrowingNumber.vue";
import LogModal from "@/views/Deposit/Log.vue";

export default {
  name: 'InvestorDetail',
  components: {CardComponent,GrowingNumber},
  data() {
    return {
      totals: [],
      isLoading: false,
      form: {
        username: null,
        name: null,
        surname: null,
        isProblem: false
      },
      blacklistId: null,
      table: {
        filter: [
          {
            type: 'input',
            value: 'DEPOSIT',
            hidden: true,
            field: 'type'
          },
          {
            type: 'input',
            value: null,
            field: 'processId',
            name: 'Process ID'
          },
          {
            type: 'input',
            value: null,
            field: 'uuid',
            name: 'İşlem ID'
          },
          {
            type: 'input',
            value: null,
            field: 'name',
            name: 'Kullanıcı'
          },
          {
            type: 'input',
            value: null,
            field: 'amount',
            name: 'Tutar'
          },
          {
            type: 'enum',
            value: null,
            field: 'status',
            name: 'Durum',
            enumType: 'transactionTypes'
          },
          {
            type: 'datepicker',
            value: [new Date(), new Date()],
            field: 'dates',
            name: 'Tarih'
          }
        ],
        page: {
          title: 'Yatırımlar',
          icon: 'cash-multiple',
          actions: [
            {
              title: 'Excel Export',
              type: 'success',
              icon: 'file-excel',
              perm: 'Deposit.ExcelExport',
              action: () => {
                this.getExportExcel()
              }
            },
            {
              title: 'Filtrele',
              type: 'info',
              icon: 'sync',
              action: () => {
                this.$refs.table.loadAsyncData()
              }
            }
          ]
        },
        settings: {
          init: false,
          service: TransactionService,
          method: 'list',
          columns: [
            {
              field: 'status',
              label: 'Durum',
              sortable: true,
              renderComponent: DepositStatus
            },
            {
              field: 'site.name',
              label: 'Site'
            },
            {
              field: 'bank.name',
              label: 'Banka',
              renderHtml: true,
              column: 'row',
              viewMethod: val => `${val.bankAccount.name} (${val.bankAccount.method})`
            },
            {
              field: 'uuid',
              label: 'İşlem ID',
              sortable: true
            },
            {
              field: 'username',
              label: 'Kullanıcı',
              sortable: true,
              column: 'row',
              renderHtml: true,
              viewMethod: val => `${val.name}`
            },
            {
              field: 'total',
              label: 'Ücret',
              sortable: true,
              column: 'row',
              renderHtml: true,
              viewMethod: (val) => `${this.currencies[val.fromCurrency]}${this.numberFormat(val.total)}`
            },
            {
              field: 'createdAt',
              label: 'Kayıt T.',
              sortable: true,
              viewMethod: val => this.dateFormat(val, this.dateFormats.normalDateTimeS)
            },
            {
              field: 'actions',
              column: 'id',
              label: 'İşlemler',
              width: '100px',
              actions: [
                {
                  title: 'Loglar',
                  type: 'warning',
                  perm: 'Deposit.Log',
                  column: 'row',
                  icon: 'format-list-text',
                  size: 'is-small',
                  class: 'has-black',
                  action: data => {
                    this.openModal({
                      component: LogModal,
                      styles: {width: 1000, height: 800},
                      props: {
                        withdraw: {...data},
                      },
                      close: this.$refs.table.loadAsyncData
                    })
                  }
                }
              ]
            }
          ]
        }
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const customer = await Service.edit(this.$route.params.id)
      if (!customer.data) {
        await this.$router.push({name: '404'})
        return
      }
      this.form = customer.data
      this.table.filter.push({type: 'input', value: this.form.userId, field: 'userId', hidden: true})
      this.$refs.table.loadAsyncData()
      const blacklist = await BlacklistService.list({type: 'userId', value: customer.data.userId})
      if (blacklist.data.docs.length > 0) {
        this.blacklistId = blacklist.data.docs[0].uuid
      }

      Service.transactionReport(this.form.userId)
          .then(res => {
            this.totals = res.data
          })
          .catch(err => this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'}))
    },
    addBlacklist() {
      BlacklistService.create({type: 'userId', value: this.form.userId})
          .then(() => {
            this.$buefy.toast.open({message: "Yatırımcı blacklist'e eklendi", type: 'is-success'})
            this.getData()
          })
          .catch(err => this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'}))
    },
    removeBlacklist() {
      BlacklistService.remove(this.blacklistId)
          .then(() => {
            this.$buefy.toast.open({message: "Yatırımcı blacklist'ten çıkarıldı", type: 'is-success'})
            this.getData()
            this.blacklistId = null
          })
          .catch(err => this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'}))
    },
    addProblem() {
      this.form.isProblem = true
      Service.update(this.form)
          .then(() => {
            this.$buefy.toast.open({message: "Yatırımcı problemli olarak işaretlendi", type: 'is-success'})
            this.getData()
          })
          .catch(err => this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'}))
    },
    removeProblem() {
      this.form.isProblem = false
      Service.update(this.form)
          .then(() => {
            this.$buefy.toast.open({message: "Yatırımcı problemsiz olarak işaretlendi", type: 'is-success'})
            this.getData()
          })
          .catch(err => this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'}))
    }
  }
}
</script>
